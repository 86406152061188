import React, { useState, useEffect, useMemo, CSSProperties } from "react";
import { Modal, Icon, List } from "semantic-ui-react";

import moment from "moment";
import { momentLocalizer } from "react-big-calendar";
import { Calendar } from "react-big-calendar";

// UI
import CardManageOperatingRoomUX from "../ORM/CardManageOperatingRoomUX";
import CardSettingTimeOperatingRoom from "../ORM/CardSettingTimeOperatingRoom";
import SnackMessage from "../../common/SnackMessage";
import { useIntl } from "react-intl";

const styles = {
  flexCenter: {
    display: "flex",
    padding: "3px",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    overflow: "auto",
  } as CSSProperties,

  paddingTextLeftSlot: {
    padding: "5px 0px",
    textAlign: "left",
    width: "100%",
  } as CSSProperties,
  paddingTextRightSlot: {
    padding: "5px 0px",
    textAlign: "right",
    width: "100%",
  } as CSSProperties,
  paddingHeaderSlot: {
    padding: "5px 0px",
    fontSize: "16px",
    fontWeight: "bold",
  } as CSSProperties,
  paddingTimeSlot: {
    padding: "5px 0px 10px 0px",
  } as CSSProperties,
};

const CARD_SETTING_TIME_OPERATING_ROOM = "CardSettingTimeOperatingRoom";

console.log("initial moment locale en week dow 1")
moment.updateLocale("en", { week: { dow: 1 } });
const localizer = momentLocalizer(moment);

const CardManageOperatingRoom = (props: any) => {
  const [selectedRoom, setSelectedRoom] = useState<any>(null);

  useEffect(() => {
    props.runSequence({ sequence: "ManageOR", restart: true });
  }, []);

  const locationRoom = useMemo(() => {
    const { selectedRoom } = props.ManageORSequence || {};
    return selectedRoom
      ? `${selectedRoom.location_name} -  ${selectedRoom.room_no}`
      : "-";
  }, [props.ManageORSequence?.selectedRoom]);

  const blockList = useMemo(() => {
    const renderRoomSchedule = (item: any, index: number) => {
      let start_time = moment(item.start_datetime).format("HH:mm");
      let end_time = moment(item.end_datetime).format("HH:mm");
      return (
        <div key={`DSB${index + 1}`} style={styles.flexCenter}>
          <div
            style={styles.paddingHeaderSlot}
          >{`Room: ${item.room_name}`}</div>
          <div
            style={styles.paddingTimeSlot}
          >{props.languageUX === "th" ? `${start_time} น. - ${end_time} น.` : `${start_time} - ${end_time}`}</div>
          <div style={styles.paddingTextLeftSlot}>{item.patient_name}</div>
          <div style={styles.paddingTextLeftSlot}>{item.doctor_name}</div>
          {(item?.treatments || [])?.map((treatment: any, ti: number) => {
            return (
              <div
                key={`DSB${index + 1}T${ti}`}
                style={styles.paddingTextLeftSlot}
              >
                {treatment}
              </div>
            );
          }) || <div />}
          <div style={styles.paddingTextRightSlot}>
            {item.order_status_label}
          </div>
        </div>
      );
    };

    return (props.ManageORSequence?.blockList || [{ title: "" }]).map(
      (item: any, index: number) => ({
        ...item,
        title: !item.doctor && item.room ? "" : renderRoomSchedule(item, index),
      })
    );
  }, [props.ManageORSequence?.blockList]);

  const handleSelectRoom = (room: any) => {
    props.runSequence({ sequence: "ManageOR", action: "select_room", room });
  };

  const eventPropGetter = (
    event: any,
    start: Date,
    end: Date,
    isSelected: boolean
  ) => {
    // console.log("CardDentistSchedule blockList: ", blockList);
    // console.log("CardDentistSchedule event", event);
    return {
      style:
        event.room && !event.doctor
          ? {
              backgroundColor: "#E9E9E9",
              color: "#121010",
              borderColor: "#E9E9E9",
              borderRadius: "none",
            }
          : {
              backgroundColor: "transparent",
              color: "#FF0000",
              borderColor: "#FF0000",
            },
    };
  };

  const handleChangeValue = async (e: any, v: any) => {
    const value = typeof v.checked === "boolean" ? v.checked : v.value;

    props.runSequence({
      sequence: "ManageOR",
      action: "change_setting_time",
      value,
      name: v.name
    });
  };

  const handleSelectSlot = (info: any) => {
    const findBlock = blockList.find((item: any) =>
      moment(info.start).isBetween(item.start, item.end)
    );

    const isActionValid = ["click", "select"].includes(info.action);

    if (
      props.ManageORSequence.selectedRoom &&
      isActionValid &&
      info &&
      !findBlock
    ) {
      props.runSequence({
        sequence: "ManageOR",
        action: "select_slot",
        slot: info,
      });
    }
  };

  const handleClose = async () => {
    await props.setProp(`ManageORSequence.settingTimeOR`, {});

    props.runSequence({
      sequence: "ManageOR",
      action: "select_slot",
      slot: null,
    });
  };

  const handleSave = () => {
    props.runSequence({
      sequence: "ManageOR",
      action: "save_time",
      card: CARD_SETTING_TIME_OPERATING_ROOM,
    });
  };

  const handleCloseMessage = () => {
    props.setProp(`errorMessage.${CARD_SETTING_TIME_OPERATING_ROOM}`, null);
    props.setProp(`successMessage.${CARD_SETTING_TIME_OPERATING_ROOM}`, null);
  };

  console.log(props);

  return (
    <div style={{ height: "95vh" }}>
      <CardManageOperatingRoomUX
        // data
        title={locationRoom}
        // Element
        selectCalendar={
          <Calendar
            defaultView="week"
            eventPropGetter={eventPropGetter}
            events={blockList}
            selectable={true}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            onSelectEvent={(event: any) => {
              // console.log("event", event);
            }}
            onSelectSlot={handleSelectSlot}
            longPressThreshold={10}
            views={["week", "day"]}
          ></Calendar>
        }
        LocationElement={
          <>
            {(props.ManageORSequence?.locationRoomList || []).map(
              (item: any, index: number) => (
                <Accordion
                  key={"accordion-" + index}
                  item={item}
                  selected={props.ManageORSequence?.selectedRoom}
                  onSelect={handleSelectRoom}
                />
              )
            )}
          </>
        }
        languageUX={props.languageUX}
      />
      <Modal
        open={!!props.ManageORSequence?.selectedSlot}
        closeOnDimmerClick
        onClose={handleClose}
      >
        <CardSettingTimeOperatingRoom
          // data
          data={props.ManageORSequence?.settingTimeOR || {}}
          // callback
          onChangeValue={handleChangeValue}
          onCancel={handleClose}
          onSave={handleSave}
          languageUX={props.languageUX}
        />
      </Modal>

      <SnackMessage
        onEvent={() => null}
        onClose={handleCloseMessage}
        error={props.errorMessage?.[CARD_SETTING_TIME_OPERATING_ROOM]}
        success={null}
        languageUX={props.languageUX}
      />
    </div>
  );
};

const Accordion = (props: any) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleSelect = (item: any, index: number) => (e: any) => {
    if (props.selected?.id === item.id) {
      props.onSelect?.(null);
    } else {
      props.onSelect?.({ ...item, location_name: props.item.name });
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "rgba(198,235,243,0.75)",
          padding: "1.1rem",
          borderBottom: "1px solid rgb(202,202,202)",
          display: "flex",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleToggle}
      >
        <div style={{ display: "flex" }}>
          <Icon name="map marker alternate" style={{ color: "#665757" }} />
          <label style={{ marginLeft: "0.75rem" }}>{props.item.name}</label>
        </div>

        <Icon
          name={toggle ? "triangle up" : "triangle down"}
          style={{ color: "#665757", fontSize: "1.5rem" }}
        />
      </div>
      {!!props.item?.items?.length && toggle && (
        <>
          {props.item.items.map((item: any, index: number) => (
            <div
              key={"accordion-item" + index}
              style={{
                backgroundColor:
                  props.selected?.id === item.id
                    ? "rgba(217,217,217,0.41)"
                    : "",
                padding: "0.9rem 0 0.9rem 2.5rem",
                borderBottom:
                  index === props.item.items.length - 1
                    ? ""
                    : "1px solid rgb(202,202,202)",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={handleSelect(item, index)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#665757",
                    width: "5px",
                    height: "5px",
                    borderRadius: "500rem",
                  }}
                ></div>
                <label style={{ marginLeft: "1rem" }}>{item.room_no}</label>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default React.memo(CardManageOperatingRoom);
